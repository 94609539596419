import React from 'react'
import { BsGear } from 'react-icons/bs'
import About from './about'

const button = 'w-8 h-8 cursor-pointer'

const Header = () =>
  <header className='flex justify-between items-center text-gray-400 p-6'>
    <BsGear className={`${button}`}/>
    <span className={`
      font-extrabold text-2xl
      bg-gradient-to-r from-red-500 to-green-500 via-blue-500 text-transparent bg-clip-text
    `}>COLORFALL</span>
    <About/>
  </header>

export default Header