import { COLORS } from '../constants'
import generateRandomColor from './generate_random_color'
import generateRandomLetter from './generate_random_letter'

const colors = [
  COLORS.BLUE,  
  COLORS.YELLOW,
  COLORS.RED,
  COLORS.BLUE,
  COLORS.YELLOW,
  COLORS.RED,
  COLORS.GREEN,
  COLORS.BLUE,
  COLORS.GREEN,
]

const initializeSquares = size => {
  const line = [...Array(size).keys()]
  return [].concat.apply([], line.map(row => line.map(column => ({
    identifier: row * size + column,
    row,
    column,
    opacity: 1,
    color: generateRandomColor(), // colors[row * size + column] //
    letter: generateRandomLetter()
  }))))
}

export default initializeSquares