const selection = {
  "E": 21912,
  "T": 16587,
  "A": 14810,
  "O": 14003,
  "I": 13318,
  "N": 12666,
  "S": 11450,
  "R": 10977,
  "H": 10795,
  "D": 7874,
  "L": 7253,
  "U": 5246,
  "C": 4943,
  "M": 4761,
  "F": 4200,
  "Y": 3853,
  "W": 3819,
  "G": 3693,
  "P": 3316,
  "B": 2715,
  "V": 2019,
  "K": 1257,
  "X": 315,
  "Q": 205,
  "J": 188,
  "Z": 12,
}

const total = Object.entries(selection).reduce((curr, prev) => curr + prev[1], 0)

// Not random -- weighted by frequency.
const generateRandomLetter = () => {

  let current = Object.entries(selection)[0][1] 
  let random = Math.floor(Math.random()*total)

  for (const [key, value] of Object.entries(selection)) {
    if (current > random) {
      return key
    }
    current += value
  }

}

export default generateRandomLetter