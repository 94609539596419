const clearMatchedSquares = () => (dispatch, getState) => {

  // const gridSquares = getState().squares.gridSquares.filter(square => !square.matched)
  
  // console.log("Setting squares", gridSquares)

  // dispatch({ type: 'SET_SQUARES', payload: { gridSquares } })

}

export default clearMatchedSquares