
import React, { useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import Developer from '../../media/developer.svg'

const About = () => {
  const [showAbout, setShowAbout] = useState(false)
  return (
    <>
      <BsQuestionCircle
        onClick={() => setShowAbout(!showAbout)}
        className={`w-8 h-8 cursor-pointer`}/>
      {showAbout &&
        <div className='fixed z-20 inset-0 flex justify-center items-center text-gray-900 bg-black/80'>
          <div className='relative max-w-sm w-full aspect-square bg-gray-50/80 rounded-xl'>
            <div className='absolute top-0 right-0 m-4'>
              <MdClose
                className='w-8 h-8 cursor-pointer'
                onClick={() => setShowAbout(false)}/>
            </div>
            <div className='h-full flex flex-col space-y-8 items-center justify-center p-12'>
              <img className='w-32 h-32' src={Developer}/>
              <div className='font-bold'>I'm still building this and there are still some bugs! For now, just drag squares to make matches.</div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default About