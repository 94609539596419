import Header from '../components/header'
import Grid from '../components/grid'
import Footer from '../components/footer'

const App = () =>
  <div className='absolute inset-0'>
    <div className='flex justify-center w-full h-full'>
      <div className='flex flex-col max-w-sm w-full'>
        <Header/>
        <Grid/>
        <Footer/>
      </div>
    </div>
  </div>

export default App