import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { resetGame } from '../../actions'

const Buttons = ({ resetGame }) =>
  <button
    onClick={() => resetGame()}
    className='border border-gray-500 rounded-md px-6 py-1'>
    Reset
  </button>


const mapDispatchToProps = dispatch => ({
  resetGame: bindActionCreators(resetGame, dispatch),
})

export default connect(null, mapDispatchToProps)(Buttons)