import { initializeSquares } from '../functions'

const gridSize = 4

const initialState = {
  gridSquares: initializeSquares(gridSize),
  mergedSquares: [],
  matches: [],
  queued: [],
  gridSize,
  cellSize: null,
  activeSquare: null,
  mergedSquare: null,
  mouseDragPosition: {
    x: null,
    y: null
  }
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case "RESET_GAME":
      return {
        ...initialState,
        cellSize: state.cellSize,
        gridSquares: initializeSquares(gridSize)
      }
    case "CLEAR_GAME":
      return {
        ...state,
        gridSquares: []
      }
    case "GRID_INITIALIZED":
      return {
        ...state,
        cellSize: action.payload.gridWidth / gridSize
      }
    case "CLEAR_ACTIVE_SQUARE":
      return {
        ...state,
        activeSquare: null
      }
    case "SET_ACTIVE_SQUARE": 
      return {
        ...state,
        activeSquare: action.payload.square,
        mouseStart: action.payload.mouseStart,
        mouseDragPosition: action.payload.mouseStart
      }
    case "ON_MOUSE_MOVE": 
      return {
        ...state,
        mouseDragPosition: {
          x: action.payload.x,
          y: action.payload.y
        }
      }
    case "SET_SQUARES": 
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default reducer