import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import App from './container'

const Container = ({ debug = false }) =>
  <Provider store={store}>
    <App debug={debug}/>
  </Provider>

export default Container