import { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setActiveSquare, setGridMetadata, setMousePosition } from '../../actions'
import Squares from './squares'

const Container = ({
  initialized,
  setGridMetadata,
  activeSquare,
  setActiveSquare,
  setMousePosition
}) => {

  // Initialize grid and dispatch when ready
  const gridRef = useRef(null)
  useEffect(() => {
    setGridMetadata(gridRef.current?.scrollWidth)
  }, [gridRef, setGridMetadata])

  // On mouseup, clear the active square
  useEffect(() => {
    document.addEventListener("mouseup", () => setActiveSquare(null))
    document.addEventListener("ontouchend", () => setActiveSquare(null))
    document.addEventListener("ontouchmove", e => e.preventDefault(), false)
  }, [setActiveSquare])

  return (
    <div className='h-full my-8'>
      <div
        ref={gridRef}
        className={`text-white relative aspect-square touch-none`}
        onTouchMove={e => {
          console.log(e)
          e.preventDefault()
          activeSquare && setMousePosition(e.touches[0].pageX, e.touches[0].pageY)
        }}
        onMouseMove={e =>{
          console.log(e)
          activeSquare && setMousePosition(e.pageX, e.pageY)
        }}>
        {initialized && <Squares/>}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  activeSquare: state.squares.activeSquare,
  initialized: state.squares.cellSize > 0
})

const mapDispatchToProps = dispatch => ({
  setActiveSquare: bindActionCreators(setActiveSquare, dispatch),
  setMousePosition: bindActionCreators(setMousePosition, dispatch),
  setGridMetadata: bindActionCreators(setGridMetadata, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)