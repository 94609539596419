import clearMatchedSquares from './clear_matched_squares'
import checkForMatches from './check_for_matches'

const dropSquares = () => (dispatch, getState) => {
  
  const squares = getState().squares
  const gridSize = squares.gridSize
  let gridSquares = squares.gridSquares

  let filledSquareCount = 0
  let totalSquaresCount = gridSize * gridSize

  console.log("Dropping squares...", squares)

  let maxLoops = 10
  let drops = false

  while (filledSquareCount < totalSquaresCount && maxLoops--) {

    // console.log("Dropping squares loop...", gridSquares)

    filledSquareCount = 0

    // Loop through rows starting at the bottom to find holes, then fill with all rows above
    for (let row = gridSize - 1; row >= 0; row--) {
      for (let column = 0; column < gridSize; column++) {

        let existingSquare = gridSquares.find(square =>
          square.row === row
          && square.column === column
          && !square.matched
        )
        if (!existingSquare) {
          gridSquares.filter(square => !square.matched).forEach(square => {
            if (square.column === column && square.row <= row) {
              square.row ++
              square.opacity = 1
            }
          })
          drops = true
          console.log("Dropping: ", row, column)
        }
        else {
          filledSquareCount++
          // console.log("Square exists for row: " + row + ", column: " + column, existingSquare)
        }

      }
    }

  }
  
  console.log("Setting squares", gridSquares)

  dispatch({ type: 'SET_SQUARES', payload: { gridSquares } })
  
  setTimeout(() => {
    dispatch(clearMatchedSquares())
  }, 500)

  if (drops) {
    setTimeout(() => {
      dispatch(checkForMatches())
    }, 1000)
  }
}

export default dropSquares