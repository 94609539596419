import { COLORS } from '../constants'
import generateRandomColor from '../functions/generate_random_color'
import checkForMatches from './check_for_matches'

const mergeSquares = destinationSquare => (dispatch, getState) => {

  const squares = getState().squares

  const activeSquare = squares.activeSquare
  const gridSquares = squares.gridSquares
  const gridSize = squares.gridSize
  const mergedSquares = squares.mergedSquares
  const matches = squares.matches

  console.log("Trying to merge:", activeSquare, destinationSquare)

  // Clear the active square if there is no merge possibility.
  if (!activeSquare || !destinationSquare || activeSquare.color === destinationSquare.color) {
    dispatch({ type: "CLEAR_ACTIVE_SQUARE" })
    return
  }

  const mergingColors = [activeSquare.color, destinationSquare.color]
  let newColor = getMergedColor(mergingColors)

  // If there's no match, just clear the active square.
  if (!newColor) {
    dispatch({ type: "CLEAR_ACTIVE_SQUARE" })
    return
  }

  const mergedGridSquares = mergeSquare(gridSquares, activeSquare, destinationSquare, newColor)
  const squaresCount = gridSquares.length + mergedSquares.length + (matches.length * gridSize)
  const createdSquare = createSquare(activeSquare, squaresCount)
  
  console.log("Creating ", createdSquare)

  dispatch({
    type: "SET_SQUARES",
    payload: {
      gridSquares: [...mergedGridSquares, createdSquare],
      mergedSquares: [...mergedSquares, activeSquare],
      mergedSquare: activeSquare,
      activeSquare: null
    }
  })

  dispatch(checkForMatches(destinationSquare))
}

const getMergedColor = mergingColors => {
  if (mergingColors.includes(COLORS.BLUE) && mergingColors.includes(COLORS.YELLOW)) {
    return COLORS.GREEN
  }
  else if (mergingColors.includes(COLORS.RED) && mergingColors.includes(COLORS.YELLOW)) {
    return COLORS.ORANGE
  }
  else if (mergingColors.includes(COLORS.BLUE) && mergingColors.includes(COLORS.RED)) {
    return COLORS.PURPLE
  }
  else if (mergingColors.includes(COLORS.PURPLE) && mergingColors.includes(COLORS.YELLOW)) {
    return COLORS.BROWN
  }
  else if (mergingColors.includes(COLORS.ORANGE) && mergingColors.includes(COLORS.BLUE)) {
    return COLORS.BROWN
  }
  else if (mergingColors.includes(COLORS.GREEN) && mergingColors.includes(COLORS.RED)) {
    return COLORS.BROWN
  }
}

const mergeSquare = (gridSquares, activeSquare, destinationSquare, color) => {

  console.log("Blending " + color)
  console.log("Merged square:", activeSquare)

  return gridSquares.filter(square => square.identifier !== activeSquare.identifier).map(square => {
    if (square.identifier === destinationSquare.identifier) {
      square.color = color
    }
    return square
  })

}

const createSquare = (activeSquare, squaresCount) => ({
  identifier: squaresCount,
  column: activeSquare.column,
  row: -1,
  opacity: 1,
  color: generateRandomColor()
})

export default mergeSquares