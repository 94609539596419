const calculateOffset = (row, column, gridSize, isActive, mouseDragPosition, mouseStart, cellSize) => {

  let offsetX = isActive && mouseDragPosition ? mouseDragPosition.x - mouseStart.x : 0
  let offsetY = isActive && mouseDragPosition ? mouseDragPosition.y - mouseStart.y : 0

  // Reset one so we slide along axis
  if (Math.abs(offsetX) > Math.abs(offsetY)) {
    offsetY = 0
  }
  else {
    offsetX = 0
  }

  // Cap out how far we can move it into the grid (cell size restricted)
  if (Math.abs(offsetX) > cellSize) {
    offsetX = offsetX >= 0 ? cellSize : -cellSize
  }
  if (Math.abs(offsetY) > cellSize) {
    offsetY = offsetY >= 0 ? cellSize : -cellSize
  }

  // Handle x-capping on edges
  if (column === 0 && offsetX < 0) {
    offsetX = 0
  }
  else if (column === gridSize - 1 && offsetX > 0) {
    offsetX = 0
  }

  // Handle y-capping on edges
  if (row === 0 && offsetY < 0) {
    offsetY = 0
  }
  else if (row === gridSize - 1 && offsetY > 0) {
    offsetY = 0
  }

  return { x: offsetX, y: offsetY }
}

export default calculateOffset