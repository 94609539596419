const initializeSquares = require('./initialize_squares').default
const generateRandomColor = require('./generate_random_color').default
const generateRandomLetter = require('./generate_random_letter').default
const calculateOffset = require('./calculate_offset').default
const checkForMerge = require('./check_for_merge').default

module.exports = {
  initializeSquares,
  generateRandomColor,
  generateRandomLetter,
  calculateOffset,
  checkForMerge,
}