import { useSelector } from 'react-redux'
import Square from './square'

const Squares = ({ gridRef }) =>
  useSelector(state => state.squares.gridSquares).map((square, index) =>
    <Square
      square={square}
      key={square.identifier}
      grid={gridRef}/>
  )

export default Squares