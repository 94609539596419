import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setActiveSquare, mergeSquares } from '../../actions'
import { calculateOffset, checkForMerge } from '../../functions'

const Square = ({
  square: {
    identifier,
    row,
    column,
    color,
    opacity,
    letter
  },
  square,
  squares: {
    gridSize,
    cellSize,
    gridSquares,
    mouseStart,
    mouseDragPosition,
    activeSquare
  },
  mergeSquares,
  setActiveSquare
}) => {

  const isActive = activeSquare?.identifier === identifier

  // Handle position
  const [position, setPosition] = useState({ top: row * cellSize, left: column * cellSize  })

  useEffect(() => {
    const offset = calculateOffset(row, column, gridSize, isActive, mouseDragPosition, mouseStart, cellSize)
    setPosition({
      top: row * cellSize + offset.y,
      left: column * cellSize + offset.x
    })

    const destinationSquare = checkForMerge(gridSquares, square, offset, cellSize)
    if (destinationSquare !== null) {
      mergeSquares(destinationSquare)
    }

  }, [row, column, gridSquares, square, cellSize, gridSize, isActive, mouseStart, mouseDragPosition, mergeSquares])

  return (
    <button
      key={identifier}  
      style={{ ...position, opacity, width: cellSize, }}
      className={`${color}
        ${opacity === 0 ? '-z-10' : ''}
        ${isActive ? 'border-white/50 z-10 border-[2px] transition-colors' : 'z-0 border-black border-4 transition-all'} 
        duration-1000 absolute aspect-square flex items-center justify-center
        rounded-2xl
      `}
      onTouchStart={e => {
        setActiveSquare(square, e.touches[0].clientX, e.touches[0].clientY)
        // e.stopPropagation()
        // e.preventDefault()
      }}
      onTouchEnd={() => setActiveSquare(null)}
      onMouseDown={e => {
        setActiveSquare(square, e.pageX, e.pageY)
        e.stopPropagation()
        e.preventDefault()
      }}>
      <div className='absolute top-1 left-1 text-sm'>{identifier}</div>
      {/* <div className='text-3xl font-bold'>{letter}</div> */}
      <div className='absolute bottom-1 right-1 text-sm'>{row}:{column}</div>
    </button>
  )
}

const mapStateToProps = state => ({
  squares: state.squares
})

const mapDispatchToProps = dispatch => ({
  mergeSquares: bindActionCreators(mergeSquares, dispatch),
  setActiveSquare: bindActionCreators(setActiveSquare, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Square)