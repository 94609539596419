const setActiveSquare = (square, mouseX, mouseY) => dispatch =>
  dispatch({
    type: "SET_ACTIVE_SQUARE",
    payload: {
      square,
      mouseStart: {
        x: mouseX,
        y: mouseY
      }
    }
  })

export default setActiveSquare