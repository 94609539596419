const checkForMerge = (squares, activeSquare, offset, cellSize) => {

  if (!activeSquare) {
    return null
  }

  squares = squares.filter(square => !square.matched)

  // Merging right
  if (offset.x === cellSize) {
    console.log("Merging right", offset);
    return squares.find(square =>
      square.column === activeSquare.column + 1
      && square.row === activeSquare.row)
  }

  // Merging left
  if (offset.x === -cellSize) {
    console.log("Merging left", offset);  
    return squares.find(square =>
      square.column === activeSquare.column - 1
      && square.row === activeSquare.row)
  }
  
  // Merging down
  if (offset.y === cellSize) {
    console.log("Merging down", offset);
    return squares.find(square =>
      square.column === activeSquare.column
      && square.row === activeSquare.row + 1)
  } 

  // Merging up
  if (offset.y === -cellSize) {
    console.log("Merging up", offset);
    return squares.find(square =>
      square.column === activeSquare.column
      && square.row === activeSquare.row - 1)
  }

  return null

}

export default checkForMerge