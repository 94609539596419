import dropSquares from './drop_squares'
import generateRandomColor from '../functions/generate_random_color'

const checkForMatches = (destinationSquare) => (dispatch, getState) => {

  console.log("Checking for matches based on destination square", destinationSquare)

  const squares = getState().squares

  let gridSquares = squares.gridSquares
  let matches = squares.matches
  const gridSize = squares.gridSize
  const mergedSquares = squares.mergedSquares

  const squareCount = gridSquares.length + mergedSquares.length + (matches.length * gridSize)

  if (destinationSquare) {
    // Check if merging squares made a match.
    if (doesLineMatch(gridSquares, 'row', destinationSquare.row, gridSize)) {
      console.log("Row matches by merge: ", destinationSquare)
      gridSquares = handleRowMatch(gridSquares, destinationSquare.row, gridSize, squareCount, destinationSquare)
      matches = [...matches, { row: destinationSquare.row }]
      dispatch({ type: 'SET_SQUARES', payload: { gridSquares, matches } })
    }
    else if (doesLineMatch(gridSquares, 'column', destinationSquare.column, gridSize)) {
      console.log("Column matches by merge: ", destinationSquare)
      gridSquares = handleColumnMatch(gridSquares, destinationSquare.column, gridSize, squareCount, destinationSquare)
      matches = [...matches, { column: destinationSquare.column }]
      dispatch({ type: 'SET_SQUARES', payload: { gridSquares, matches } })
    }
  }
  else {
      // Loop through for matches starting on bottom row then up
      for (let row = gridSize - 1; row >= 0; row--) {
        if (doesLineMatch(gridSquares, 'row', row, gridSize)) {
          gridSquares = handleRowMatch(gridSquares, row, gridSize, squareCount, destinationSquare)
          matches = [...matches, { row }]
          dispatch({ type: 'SET_SQUARES', payload: { gridSquares, matches } })
          break
        }
      }
  
      // Loop through on columns starting from left to right
      for (let column = 0; column < gridSize; column++) {
        if (doesLineMatch(gridSquares, 'column', column, gridSize)) {
          gridSquares = handleColumnMatch(gridSquares, column, gridSize, squareCount)
          matches = [...matches, { column }]
          dispatch({ type: 'SET_SQUARES', payload: { gridSquares, matches } })
          break
        }
      }
  }

  setTimeout(() => {
    dispatch(dropSquares())
  }, 100)

}

const doesLineMatch = (gridSquares, type, value, gridSize) => {

  const lineSquares = gridSquares.filter(square => square[type] === value && !square.matched)

  if (lineSquares.length !== gridSize) {
    return false
  }

  const firstColor = lineSquares[0].color

  for (let index = 1; index < lineSquares.length; index++) {
    if (firstColor !== lineSquares[index].color) {
      return false // Different colors identified, return false early
    }
  }

  console.log("Consuming: ", type, value, lineSquares)

  return true // All colors matched, return true 
}

const handleRowMatch = (gridSquares, row, gridSize, squareCount, destinationSquare = null) => [
  ...gridSquares.map(square => {
    if (square.row === row) {
      square.opacity = 0
      square.matched = true
      square.row ++
    }
    return square
  }),
  ...createRow(squareCount, gridSize, destinationSquare)
]

const handleColumnMatch = (gridSquares, column, gridSize, squareCount) => [
  ...gridSquares.map(square => {
    if (square.column === column) {
      square.opacity = 0
      square.matched = true
      square.row ++
    }
    return square
  }),
  ...createColumn(squareCount, gridSize, column)
]

const createColumn = (squaresCount, gridSize, column) => 
  [...Array(gridSize).keys()].map(row => ({
    identifier: squaresCount + row,
    row: -1 - row,
    column: column,
    opacity: 1,
    color: generateRandomColor()
  }))

const createRow = (squaresCount, gridSize, destinationSquare = null) =>
  [...Array(gridSize).keys()].map(column => ({
    identifier: squaresCount + column,
    row: destinationSquare && destinationSquare.column === column ? -2 : -1,
    column: column,
    opacity: 1,
    color: generateRandomColor()
  }))

  export default checkForMatches