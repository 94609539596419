const resetGame = () => (dispatch) => {

  dispatch({ type: 'CLEAR_GAME' })

  setTimeout(() => {
    dispatch({ type: 'RESET_GAME' })
  }, 200)

}

export default resetGame