const setGridMetadata = require('./set_grid_metadata').default
const setActiveSquare = require('./set_active_square').default
const mergeSquares = require('./merge_squares').default
const setMousePosition = require('./set_mouse_position').default
const resetGame = require('./reset_game').default

module.exports = {
  setGridMetadata,
  setActiveSquare,
  mergeSquares,
  setMousePosition,
  resetGame
}