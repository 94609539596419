import React from 'react'
import Stats from './stats'
import Buttons from './buttons'

const Footer = () =>
  <div className='flex items-center justify-between text-gray-400 p-6'>
    <Stats/>
    <Buttons/>
  </div>

export default Footer