import React from 'react'
import { connect } from 'react-redux'

const Stats = ({ squares }) =>
  <div>
    Matches: {squares.matches.length}
  </div>

const mapStateToProps = state => ({
  squares: state.squares
})

export default connect(mapStateToProps)(Stats)